import Grid from "@mui/material/Grid"; // Grid version 1
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./App.css";
import logo from "./logo.png";
import imageAttract from "./image-attract.png";

//import { styled } from "@mui/material/styles";

function App() {
  return (
    <div className="App">
      <Box sx={{ flexGrow: 1 }} className="App-header">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          columns={{ xs: 4, sm: 8, md: 12 }}
          spacing={6}
        >
          <Grid item justifyContent="flex-start" alignItems="flex-start" xs={2}>
            <img src={logo} alt="Italian Trulli" className="logocss" />
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid
            xs={6}
            item
            justifyContent="flex-end"
            alignItems="flex-end"
            className="header-text"
          >
            <div>Be the reason for</div>
            <div className="header-text-font">someone’s smile</div>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} className="content-box">
            <p className="comming-soon">Coming Soon</p>
            <p className="text-topic">
              Empowering Communities, Transforming Lives
            </p>
            <p className="main-text">
              GAURAV SOCIAL WELFARE FOUNDATION is a non-governmental
              organization that operates in the field of education, health,
              sports, and environment. We aim to promote sustainable development
              and improve the quality of life for marginalized communities. We
              serve the community through various initiatives and programs, with
              a vision of creating a better and more sustainable future for all.{" "}
              <br></br>
              Email us today to join at voulnteers@gswf.co.in
              <br />
              Contact us at +91-94304-66551 for financial support.
            </p>
          </Grid>
          <Grid item xs={1} className="content-box"></Grid>
          <Grid item xs={6}>
            <img src={imageAttract} className="att-image" />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default App;
